<template>
  <CContainer>
    <CRow>
    <CCol col="12">
      <CCard>
        <CCardHeader>
          <h3>Generar Reportes Mailing</h3>
        </CCardHeader>
        <CCardBody>
               <CRow>
            <CCol col="4">
               <label type="text" class="col-sm-6 "><span>
                <strong>Fecha Inicio  </strong>
                </span></label>
                <input type="date" name="date-radio61" class="form-control" id="fechainicio"  v-model="dateinicio" :disabled="fechaHorarioDisabled" @change="obtenerfechainicio"> 
            </CCol>
            <CCol col="4">
                <label type="text" class="col-sm-6 "><span>
                <strong>Fecha Fin  </strong>
                </span></label>
                <input type="date" name="date-radio62"  class="form-control" id="fechafin" :min="minimofechafin" :max="maximofechafin"  v-model="datefin" @change="obtenerCambioFechaFin">          
            </CCol> 
          </CRow>
          <CRow>
            <CCol col="4">
                <label type="text" class="col-sm-9 "><span>
                <strong>Modo de Envio</strong>
                </span></label>
                  <CSelect
                        label=""
                        :options="listaModoEnvio"
                        required
                        was-validated
                        @change="filtroProyecto"
                        :value.sync="tipo_reporte"
                        >
                    </CSelect>
            </CCol>
            <CCol col="4">
                <label type="text" class="col-sm-9"><span>
                <strong>Campaña</strong>
                </span></label>
                  <multiselect v-model="proyecto" placeholder="Busca y Selecciona la Campaña" :disabled="desahabilitarModoEnvio" label="name" track-by="code" :options="itemsProyectos" :multiple="true" :taggable="true"></multiselect>
                  <!--<CSelect
                        label=""
                        :options="itemsProyectos"
                        was-validated
                        :value.sync="proyecto"
                        :disabled="desahabilitarModoEnvio"
                        >
                    </CSelect>-->
            </CCol>
          </CRow>
          <CRow>
            <CCol col="4">
                <label type="text" class="col-sm-9 "><span>
                <strong>Sub Cuentas</strong>
                </span></label>
                  <CSelect
                        label=""
                        :options="itemsSubCuentas"
                        was-validated
                        :value.sync="usuario"
                        @change="filtroProyecto"
                        :disabled="desahabilitarModoEnvioSubCuenta"
                        >
                    </CSelect>
            </CCol>
          </CRow>
          <CRow>
            <CCol col="4">
              <br>
              <button type="button"  class="btn btn-primary btn-lg btn-block" v-if="$can('reportes.index')" @click="generarReportes()">Generar</button> 
            </CCol>
          </CRow>
      </CCardBody>
      </CCard>
     
      <CCard>
        <CCardHeader>
           <CRow class="justify-content-between">
            <CCol col="8"><h3>Reportes Generados</h3></CCol>
            <CCol col="3"><CButton color="success" variant="outline" @click="actualizarTablas()" class="m-1"> Actualizar Tablas </CButton></CCol>
          </CRow>
        </CCardHeader>
        <CCardBody>
          <CDataTable
            hover
            striped
            :items="items"
            :fields="fields"
            :items-per-page="10"
            :pagination="{ doubleArrows: false, align: 'center'}"
            column-filter
            @page-change="pageChange"
          >
          <template#nombre_file="{item, index}">
            <td :class="item.nombre_file == null ? 'font-weight-bold text-warning' : ''">{{item.nombre_file == null ? 'Generando Reporte' : item.nombre_file}}</td>
          </template>
          <template#fecha_generado="{item, index}">
            <td>{{item.fecha_generado == null ? '0000-00-00 00:00:00' : item.nombre_file }}</td>
          </template>
              <template #accion="{item, index}">
                <td class="py-2">
                  <CButton
                    color="warning"
                    variant="outline"
                    square
                    size="sm"
                    @click="descargarReporte(item.nombre_file)"
                    v-if="item.estado == 'Completado'"
                  >
                    Descargar Reporte
                  </CButton>
                </td>
                <td class="py-2">
                  <CButton
                    color="success"
                    variant="outline"
                    square
                    size="sm"
                    @click="generarGraficoChart(item.data_chart)"
                    v-if="item.data_chart != null"
                  >
                    Generar Grafico
                  </CButton>
                </td>
              </template>
          </CDataTable>
        </CCardBody>
      </CCard>
    </CCol>
  </CRow>
  <div>
          <CToaster :autohide="5000" class="d-inline-block">
             <template v-for="toast in mostrarNotificacion">
                <CToast
                  :key="'toast'+toast"
                  :show="true"
                  color="success"
                  header="Notificacion Exitosa"
                >
                  {{mensaje_success}}
                </CToast>
              </template>   
              <template v-for="toast1 in mostrarNotificacionAlerta">
                <CToast
                  :key="'toast1'+toast1"
                  :show="true"
                  color="warning"
                  header="Notificacion Alerta"
                >
                  {{mensaje_warning}}
                </CToast>
              </template>
              <template v-for="toast2 in mostrarNotificacionError">
                <CToast
                  :key="'toast2'+toast2"
                  :show="true"
                  color="danger"
                  header="Notificacion Alerta"
                >
                  Error Comunicarse Con Soporte
                </CToast>
              </template>  
          </CToaster>
          <CModal
              :show.sync="reporte_chart_modal"
              :no-close-on-backdrop="true"
              :centered="true"
              title="Modal title 2"
              size="xl"
              color="dark"
            >
              <CContainer>
                <!--<CRow class="justify-content-center">
                  <CCol col="12">
                      <template>
                        <CChartBar
                          :datasets="defaultDatasets"
                          :labels="data_chart_agrupacion['Campana']"
                        />
                      </template>
                  </CCol>
              </CRow>-->
              <CRow class="justify-content-center">
                  <CCol col="12">
                    <CButton @click="CopyCanvasClipboard('chart-rodanda');" color="success" variant="outline">Copiar Reporte En Una Imagen</CButton>
                    <br>
                      <template>
                          <CChartPie
                          id="chart-rodanda"
                            :datasets="defaultDatasets1"
                            :labels="data_chart_agrupacion['Campana']"
                          />
                        </template>
                  </CCol>
              </CRow>
              <br>
              <CRow class="justify-content-center">
                  <CCol col="12">
                     <CButton @click="CopyCanvasClipboard('tabla_reporte_descripcion');" color="primary" variant="outline">Copiar Reporte En Una Imagen</CButton>
                     <br>
                     <table class="table" id="tabla_reporte_descripcion">
                        <tr>
                          <th>Campaña</th>
                          <th>Celulares</th>
                          <th>Mensajes</th>
                          <th>Fecha Cargue</th>
                        </tr>
                        <tr v-for="(item, index) in item_tabla['Campana']">
                          <td>{{item}}</td>
                          <td>{{creditosConversorMoneda(item_tabla['Celulares'][index])}}</td>
                          <td>{{creditosConversorMoneda(item_tabla['Mensajes'][index])}}</td>
                          <td>{{printDate(item_tabla['Fecha'][index])}}</td>
                        </tr>
                        <tr>
                           <td></td>
                           <td><strong>Total:</strong> {{creditosConversorMoneda(totalCelulares)}}</td> </td>
                           <td><strong>Total:</strong> {{creditosConversorMoneda(totalMensajes)}}</td>
                           <td></td>
                        </tr>
                     </table>
                     <!--<img :src="img" v-if="img" />-->
                  </CCol>
              </CRow>
              </CContainer>
              <template #header>
              <h6 class="modal-title">Reportes Graficos</h6>
              <CButtonClose @click="reporte_chart_modal = false" class="text-white"/>
            </template>
            <template #footer>
              <CButton @click="reporte_chart_modal = false" color="danger">Cancelar</CButton>
              <CButton @click="reporte_chart_modal = false" color="success" style="display:none;">Guardar</CButton>
            </template>
          </CModal>
        </div>
  </CContainer>
  
</template>

<script>
import { CChartBar, CChartPie } from "@coreui/vue-chartjs";
import domtoimage from "dom-to-image";
import Multiselect from "vue-multiselect";
import Axios from 'axios'
export default {
  name: "Reportes",
  components: { CChartBar, CChartPie, domtoimage, Multiselect },
  data() {
    return {
      mostrarNotificacion: 0,
      mensaje_success: "Datos Guardado Correctamente.",
      mostrarNotificacionAlerta: 0,
      mostrarNotificacionError: 0,
      mensaje_warning: "Debe Completar Los Datos Vacio",
      historial_select: "mail",
      items: [],
      items_filter: [],
      itemsProyectos: [],
      itemsSubCuentas: [],
      fields: [
        { key: "nombre_file", label: "Nombre del Archivo" },
        {
          key: "fecha_inicio",
          label: "Fecha inicio",
          _classes: "font-weight-bold",
        },
        { key: "fecha_fin", label: "Fecha Fin", _classes: "font-weight-bold" },
        { key: "estado", label: "Estado" },
        {
          key: "fecha_creado",
          label: "Fecha de Solicitud",
          _classes: "font-weight-bold",
        },
        {
          key: "fecha_generado",
          label: "Fecha Generado",
          _classes: "font-weight-bold",
        },
        {
          key: "accion",
          label: "",
          _style: { width: "1%" },
          sorter: false,
          filter: false,
        },
      ],
      activePage: 1,
      dateinicio: null,
      datefin: null,
      ReportesSelect: null,
      fechaHorarioDisabled: null,
      minimofechafin: null,
      maximofechafin: null,
      desahabilitarModoEnvio: true,
      desahabilitarModoEnvioSubCuenta: true,
      tipo_reporte: "integracion_json",
      proyecto: "",
      usuario: 0,
      reporte_chart_modal: false,
      item_tabla: [],
      data_chart_agrupacion: {
        Celulares: [],
        Campana: [],
      },
      img: "",
      listaModoEnvio: [
        {
          value: "integracion_json",
          label: "A traves de cargue de Api Integracion Json",
        },
        {value: 'envio_masivo_boletin',label: 'A traves de cargue masivos'},
        {value: 'mail_clicks',label: 'Reporte de Mail Clicks'},
        { value: "error_api", label: "Error Api Integracion" },
        {
          value: "todos",
          label: "Todos los de mis envios y sub Cuentas creadas",
        }
      ],
    };
  },
  methods: {
    obtenerfechainicio() {
      const now = new Date(this.dateinicio);
      const today = now;
      const maxDate = new Date(today);
      const minDate = new Date(today);
      maxDate.setDate(now.getDate() + 31);
      minDate.setDate(now.getDate() + 1);
      //console.log(maxDate);
      //console.log(minDate);
      this.minimofechafin = `${minDate.getFullYear()}-${
        minDate.getMonth() + 1 < 10
          ? "0" + (minDate.getMonth() + 1)
          : minDate.getMonth() + 1
      }-${
        minDate.getDate() < 10 ? "0" + minDate.getDate() : minDate.getDate()
      }`;
      this.maximofechafin = `${maxDate.getFullYear()}-${
        maxDate.getMonth() + 1 < 10
          ? "0" + (maxDate.getMonth() + 1)
          : maxDate.getMonth() + 1
      }-${
        maxDate.getDate() < 10 ? "0" + maxDate.getDate() : maxDate.getDate()
      }`;
      this.datefin = this.maximofechafin;
      //console.log(this.minimofechafin);
      //console.log(this.maximofechafin);
      this.obtenerproyectoPorfecha();
      this.obtenerSubcuentas();
    },
    goBack() {
      this.usersOpened
        ? this.$router.go(-1)
        : this.$router.push({ path: "/roles" });
    },
    descargarReporte(nombre) {
      var url = this.$httpUrlMail + `/mail/reporte/descargar/${nombre}`;
      window.open(url, "_blank");
    },
    generarReportes() {
      if (
        this.dateinicio != null &&
        this.datefin != null &&
        this.tipo_reporte != ""
      ) {
        this.$store.dispatch("auth/loading_http", "visible");
        this.$http
          .post("/mail/reporte/generar", {
            url:"/mail/reporte/generar",
            fechainicio: this.dateinicio,
            fechafin: this.datefin,
            tipo_reporte: this.tipo_reporte,
            idproyecto: this.proyecto,
            iduser: this.usuario,
          })
          .then((response) => {
            let data = response.data;
            if (data == "OK") {
              this.mensaje_success =
                "El Reporte se Esta Generando Pronto Prodra Descargarlo";
              this.mostrarNotificacion++;
              this.obtenerlistaReportesGenerados();
            } else if (data == "NO DATA") {
              this.mostrarNotificacionAlerta++;
              this.mensaje_warning =
                "Las Fechas Consultadas No Contienen Registros";
            }
          })
          .catch((e) => {
            this.mostrarNotificacionError++;
          })
          .finally(() => this.$store.dispatch("auth/loading_http", "hidden"));
      } else {
        this.mostrarNotificacionAlerta++;
        this.mensaje_warning = "Debe Completar Los Datos Vacio";
      }
    },
    obtenerproyectoPorfecha() {
      //console.log(this.usuario);
      if (
        this.dateinicio != null &&
        this.datefin != null &&
        this.usuario != 0 &&
        (this.tipo_reporte != "error_api" || this.tipo_reporte != "todos")
      ) {
        this.$store.dispatch("auth/loading_http", "visible");
        this.$http
          .post("/mail/reporte/lista/proyectos", {
            fechainicio: this.dateinicio,
            fechafin: this.datefin,
            tipo_reporte: this.tipo_reporte,
            iduser: this.usuario,
          })
          .then((response) => {
            let data = response.data;
            if (data.length > 0) {
              this.desahabilitarModoEnvio = false;
              this.itemsProyectos = [];
              //this.itemsProyectos.push({code:0,name:'Seleccionar Campaña'})
              for (const iterator of data) {
                this.itemsProyectos.push({
                  code: iterator.idproyecto,
                  name: iterator.proyecto,
                });
              }
            } else {
              this.desahabilitarModoEnvio = true;
              this.itemsProyectos = [];
              this.proyecto = "";
            }
          })
          .catch((e) => {
            this.mostrarNotificacionError++;
          })
          .finally(() => this.$store.dispatch("auth/loading_http", "hidden"));
      } else {
        this.desahabilitarModoEnvio = true;
        this.itemsProyectos = [];
        this.proyecto = "";
      }
    },
    obtenerlistaReportesGenerados() {
     this.$store.dispatch("auth/loading_http", "visible");
     this.$http
        .post("/mail/reportes",{
          url:"/mail/reportes",
        })
        .then((response) => {
          this.items = response.data;
          this.items.map((item, id) => {
            if (id % 2 == 0) {
              item["_classes"] = "table-now";
            }
            return item;
          });
        })
        .catch((e) => {
          this.mostrarNotificacionError++;
        })
        .finally(() => this.$store.dispatch("auth/loading_http", "hidden"));
    },
    obtenerCambioFechaFin() {
      //console.log(this.dateinicio,this.datefin);
      this.obtenerproyectoPorfecha();
      this.obtenerSubcuentas();
      this.filtroProyecto();
    },
    validator(val) {
      return val ? val.length >= 4 : false;
    },
    filtroProyecto() {
      if (
        this.dateinicio != null &&
        this.datefin != null &&
        (this.tipo_reporte == "archivo" ||
          this.tipo_reporte == "individual" ||
          this.tipo_reporte == "grupo")
      ) {
        this.desahabilitarModoEnvioSubCuenta = false;
      }
      if (
        this.dateinicio != null &&
        this.datefin != null &&
        this.tipo_reporte == "todos"
      ) {
        if (this.itemsSubCuentas.length > 50) {
          this.desahabilitarModoEnvioSubCuenta = false;
        } else {
          this.desahabilitarModoEnvioSubCuenta = true;
          this.proyecto = "";
          this.usuario = 0;
        }
      }
      if (
        this.dateinicio != null &&
        this.datefin != null &&
        this.tipo_reporte == "error_api"
      ) {
        this.desahabilitarModoEnvioSubCuenta = false;
        this.proyecto = "";
        this.usuario = 0;
      }
      if (
        this.dateinicio != null &&
        this.datefin != null &&
        this.tipo_reporte == "mensajes_entrada"
      ) {
        if (this.itemsSubCuentas.length > 50) {
          this.desahabilitarModoEnvioSubCuenta = false;
        } else {
          this.desahabilitarModoEnvioSubCuenta = true;
          this.proyecto = "";
          this.usuario = 0;
        }
      }
      /*if (this.dateinicio != null && this.datefin != null && (this.tipo_reporte != 'error_api' || this.tipo_reporte != 'todos' || this.tipo_reporte != 'mensajes_entrada')) {
          //this.desahabilitarModoEnvio = false; 
          this.desahabilitarModoEnvioSubCuenta = false;
        }else if(this.dateinicio != null && this.datefin != null && this.tipo_reporte == 'todos'){
          //this.desahabilitarModoEnvio = true;
          this.desahabilitarModoEnvioSubCuenta = true;
          this.proyecto = ''
          this.usuario = 0
        }else if(this.dateinicio != null && this.datefin != null && this.tipo_reporte == 'error_api'){
          //this.desahabilitarModoEnvio = true;
          this.desahabilitarModoEnvioSubCuenta = false;
          this.proyecto = ''
          this.usuario = 0
        }else{
          //this.desahabilitarModoEnvio = true;
          this.desahabilitarModoEnvioSubCuenta = true;
          this.proyecto = ''
          this.usuario = 0
        }*/
      this.obtenerSubcuentas();
      this.obtenerproyectoPorfecha();
    },
    pageChange(val) {
      this.$router.push({ query: { page: val } });
    },
    formatNumber(n) {
      // format number 1000000 to 1,234,567
      return n.replace(/\D/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    actualizarTablas() {
      this.obtenerlistaReportesGenerados();
    },
    printDate: function (time) {
      return new Date(time).toLocaleDateString();
    },
    generarGraficoChart(data_chart) {
      try {
        let data = JSON.parse(data_chart);
        this.item_tabla = data;
        const propertyNames = Object.values(data["Campana"]);
        let colores = [];
        for (let index = 0; index < propertyNames.length; index++) {
          colores.push(this.colorHEX());
        }
        this.data_chart_agrupacion = {
          Campana: Object.values(data["Campana"]),
          Celulares: Object.values(data["Celulares"]),
          Mensajes: Object.values(data["Mensajes"]),
          Fecha: Object.values(data["Fecha Cargue"]),
          colores: colores,
        };
        this.item_tabla = this.data_chart_agrupacion;
        this.reporte_chart_modal = true;
        //console.log(this.data_chart_agrupacion);
      } catch (error) {
        this.mostrarNotificacionAlerta++;
        this.mensaje_warning = "No se Pudo Generar La Grafica Ocurrio Un Error";
      }
    },
    generarLetra() {
      var letras = [
        "a",
        "b",
        "c",
        "d",
        "e",
        "f",
        "0",
        "1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8",
        "9",
      ];
      var numero = (Math.random() * 15).toFixed(0);
      return letras[numero];
    },
    colorHEX() {
      var coolor = "";
      for (var i = 0; i < 6; i++) {
        coolor = coolor + this.generarLetra();
      }
      return "#" + coolor;
    },
    CopyCanvasClipboard(capture) {
      var node = document.getElementById(capture);
      let $self = this;
      domtoimage
        .toPng(node, { bgcolor: "#fff" })
        .then(function (dataUrl) {
          const contentType = "image/png";
          const blob = $self.b64toBlob(dataUrl);
          const blobUrl = URL.createObjectURL(blob);
          //$self.img = dataUrl
          //$self.copyURL(dataUrl)
          $self.asyncCall(blob);
          $self.mensaje_success = "Imagen Copiada Correctamente";
          $self.mostrarNotificacion++;
        })
        .catch(function (error) {
          $self.mostrarNotificacionAlerta++;
          $self.mensaje_warning = "No se Pudo Copiar la Imagen";
        });
    },
    creditosConversorMoneda(value) {
      const formatterDolar = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
      });
      return formatterDolar.format(value).replace("$", "");
    },
    async copyURL(mytext) {
      try {
        await navigator.clipboard.writeText(mytext);
        alert("Copied");
      } catch ($e) {
        alert("Cannot copy");
      }
    },
    b64toBlob(dataURI) {
      var byteString = atob(dataURI.split(",")[1]);
      var ab = new ArrayBuffer(byteString.length);
      var ia = new Uint8Array(ab);
      for (var i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
      }
      return new Blob([ab], { type: "image/png" });
    },
    async askWritePermission() {
      try {
        const { state } = await navigator.permissions.query({
          name: "clipboard-write",
        });
        return state === "granted";
      } catch (error) {
        return false;
      }
    },
    async setToClipboard(blob) {
      const data = [new ClipboardItem({ [blob.type]: blob })];
      await navigator.clipboard.write(data);
    },
    async asyncCall(blob) {
      const canWriteToClipboard = await this.askWritePermission();
      if (canWriteToClipboard) {
        await this.setToClipboard(blob);
      }
      return canWriteToClipboard;
    },
    obtenerSubcuentas() {
      if (
        this.dateinicio != null &&
        this.datefin != null &&
        this.tipo_reporte != "todos" &&
        this.tipo_reporte != "mensajes_entrada"
      ) {
        this.$store.dispatch("auth/loading_http", "visible");
        this.$http
          .post("/mail/reporte/lista/subcuentas",{url:"/mail/reporte/lista/subcuentas"})
          .then((response) => {
            let data = response.data;
            if (data.length > 0) {
              this.desahabilitarModoEnvioSubCuenta = false;
              this.itemsSubCuentas = [];
              this.itemsSubCuentas.push({
                value: 0,
                label: "Seleccionar Usuario",
              });
              for (const iterator of data) {
                this.itemsSubCuentas.push({
                  value: iterator.id,
                  label: iterator.name + ` (${iterator.padre})`,
                });
              }
            } else {
              this.desahabilitarModoEnvioSubCuenta = true;
              this.itemsSubCuentas = [];
              this.usuario = 0;
            }
          })
          .catch((e) => {
            this.mostrarNotificacionError++;
          })
          .finally(() => this.$store.dispatch("auth/loading_http", "hidden"));
      }
    },
  },
  mounted() {
    setTimeout(() => {
      this.obtenerlistaReportesGenerados();
    }, 100);
  },
  computed: {
    defaultDatasets() {
      return [
        {
          label: "Historial Reporte",
          backgroundColor: this.data_chart_agrupacion["colores"],
          data: this.data_chart_agrupacion["Celulares"],
        },
      ];
    },
    defaultDatasets1() {
      return [
        {
          backgroundColor: this.data_chart_agrupacion["colores"],
          data: this.data_chart_agrupacion["Celulares"],
        },
      ];
    },
    totalCelulares() {
      return this.item_tabla["Celulares"] != undefined
        ? this.item_tabla["Celulares"].reduce(
            (a, b) => parseInt(a) + parseInt(b),
            0
          )
        : "";
    },
    totalMensajes() {
      return this.item_tabla["Mensajes"] != undefined
        ? this.item_tabla["Mensajes"].reduce(
            (a, b) => parseInt(a) + parseInt(b),
            0
          )
        : "";
    },
  },
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>